const userMenu = [{
        title: "Dashboard",
        name: "Dashboard",
        icon: "dashboard",
        path: "/dashboard"
    },
    {
        title: "Profile",
        name: "Profile",
        icon: "settings",
        path: "/profile"
    },
    {
        title: "Purchase DID",
        name: "Purchase DID",
        icon: "card_travel",
        path: "/purchasedid"
    },
    {
        title: "Orders",
        name: "Orders",
        icon: "shopping_cart",
        path: "/orders"
    },
    // {
    // 	title: "callflow",
    // 	name: "callflow",
    // 	icon: "shopphping_cart",
    // 	path: "/callflow"
    // },
    {
        title: "Billing/Top Up",
        group: true,
        icon: "list",
        items: [{
                name: "Recharge",
                title: "Recharge",
                path: "/billingAcc",
                icon: "account_balance"
            },
            {
                name: "Transaction",
                title: "Transaction",
                path: "/transaction",
                icon: "credit_card"
            },
        ]
    },
    {
        title: "Launch DIDnPBX",
        name: "Launch DIDnPBX",
        icon: "phone",
        path: "/callflow"
    },
    {
        title: "Call Detail Record",
        name: "Call Detail Record",
        icon: "mdi-record-rec",
        path: "/cdr"
    },
    {
        title: "Number Manager",
        name: "Number Manager",
        icon: "shopping_bag",
        path: "/numberManager"

    },
    {
        title: "Ticket",
        name: "Ticket",
        icon: "theaters",
        path: "/ticket"
    },
    {
        title: "Live Call",
        name: "Live Call",
        icon: "swap_horiz",
        path: "/livecall"
    },
      {
        title: "Device",
        name: "Device",
        icon: "devices",
        path: "/device"
      },
    {
        title: "Rate List",
        name: "Rate List",
        icon: "money",
        path: "/ratelist"
    },
    {
        title: "Inbound SMS Log",
        name: "Inbound SMS Log",
        icon: "mdi-message-text",
        path: "/smsLog"
    },
    {
        title: "Manage Trunks",
        name: "Manage Trunks",
        icon: "devices",
        path: "/connectivityTrunk"
    },
];

export default { userMenu };